<template>
  <thead class="thead-table-paranje">
    <tr>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Kode Kandang</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'cageCodeDown'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('cageCodeUp'), changeSort('order_by', 'cage_code', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'cageCodeUp'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('cageCodeDown'), changeSort('order_by', 'cage_code', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/Vectorupper.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('cageCodeUp'), changeSort('order_by', 'cage_code', 'sort_by', 'ASC')"
                    >
                  </div>
                  <div class="sort-icon-down">
                    <img
                      :src="`${images}/icon/Vectordown.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('cageCodeDown'), changeSort('order_by', 'cage_code', 'sort_by', 'DESC')"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
          <span class="uk-text-bold">Nama Kandang</span>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
          <span class="uk-text-bold">Nama Mitra</span>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
          <span class="uk-table-expand uk-width-small uk-text-center uk-text-bold">Status</span>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
          <span class="uk-text-bold">Status Produksi</span>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
          <span class="uk-text-bold">Anak Kandang</span>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
          <span class="uk-text-bold">Pengawas Lapangan</span>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
          <span class="uk-text-bold">Tipe Kandang</span>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Siklus ke</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'logCycleCountDown'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('logCycleCountUp'), changeSort('order_by', 'log_cycle_count', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'logCycleCountUp'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('logCycleCountDown'), changeSort('order_by', 'log_cycle_count', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/Vectorupper.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('logCycleCountUp'), changeSort('order_by', 'log_cycle_count', 'sort_by', 'ASC')"
                    >
                  </div>
                  <div class="sort-icon-down">
                    <img
                      :src="`${images}/icon/Vectordown.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('logCycleCountDown'), changeSort('order_by', 'log_cycle_count', 'sort_by', 'DESC')"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
          <span class="uk-text-bold">Tahapan</span>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Tanggal Terdaftar</span>
          <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
            <div v-if="sortCode == 'createUp'">
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/Vectorupper.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('createDown'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/VectordownInnactive.svg`"
                  alt=""
                  class="img-click"
                >
              </div>
            </div>
            <div v-else-if="sortCode == 'createDown'">
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                  alt=""
                  class="img-click"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/Vectordown.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('createUp'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                >
              </div>
            </div>
            <div v-else>
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/Vectorupper.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('createDown'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/Vectordown.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('createUp'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                >
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Terakhir Diubah</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'updateDown'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('updateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'updateUp'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('updateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('updateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('updateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small uk-text-center">
        <span class="uk-text-bold">Aksi</span>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { PREFIX_IMAGE } from "@/utils/constant"

export default {
  data() {
    return {
      images: PREFIX_IMAGE,
      sortCode: "updateDown"
    }
  },
  computed: {
    ...mapGetters({
      meta: "cage/meta"
    })
  },
  methods: {
    ...mapMutations({
      setMeta: "cage/SET_META"
    }),
    changeSort(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    },
    sortSwitch(a) {
      this.sortCode = a
    }
  }
}
</script>
