<template>
  <div>
    <div class="filter-container">
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="cari"
            >Cari</label>
            <div class="uk-form-controls">
              <input
                id="cari"
                v-model="metaFilter.name"
                class="uk-input"
                type="text"
                placeholder="Cari kode / nama kandang..."
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="cage_category"
            >
              Tipe Kandang
            </label>
            <div class="uk-form-controls">
              <multiselect 
                id="cage-category-type" 
                v-model="cageCategoryChoose" 
                label="name"
                name="cage-category-type" 
                track-by="name" 
                placeholder="Semua" 
                open-direction="bottom" 
                :options="cageCategoryFilters" 
                :searchable="true" 
                :loading="isLoading"
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="farm"
            >
              Mitra
            </label>
            <div class="uk-form-controls">
              <input
                id="farm"
                v-model="metaFilter.mitra"
                class="uk-input"
                type="text"
                placeholder="Cari nama mitra"
              >
            </div>
          </div>

          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="current_cycle"
            >
              Siklus ke
            </label>
            <div class="uk-form-controls">
              <input
                id="current_cycle"
                v-model="metaFilter.current_cycle"
                class="uk-input"
                type="text"
                placeholder="Cari siklus"
              >
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="cage_staff"
            >
              Anak Kandang
            </label>
            <div class="uk-form-controls">
              <input
                id="cage_staff"
                v-model="metaFilter.cage_staff"
                class="uk-input"
                type="text"
                placeholder="Cari anak kandang"
              >
            </div>
          </div>
          <div 
            v-if="cageCategoryChoose"
            class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l"
          >
            <label
              class="uk-form-label filter-margin"
              for="log_activity"
            >
              Tahapan
            </label>
            <div class="uk-form-controls">
              <multiselect 
                id="cage-category-stages" 
                v-model="activityChoose" 
                label="name"
                name="cage-category-stages" 
                track-by="name" 
                placeholder="Semua" 
                open-direction="bottom" 
                :options="activityFilters" 
                :searchable="true" 
                :loading="isLoading"
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <!-- <select class="uk-input uk-select"
                name="log_activity"
                v-model="metaFilter.log_activity" 
                id="log_activity">
                <option value="">Semua</option>
              </select> -->
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="field_supervisor"
            >
              Pengawas Lapangan
            </label>
            <div class="uk-form-controls">
              <input
                id="field_supervisor"
                v-model="metaFilter.field_supervisor"
                class="uk-input"
                type="text"
                placeholder="Cari pengawas lapangan"
              >
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="cage_category"
            >
              Status Produksi
            </label>
            <div class="uk-form-controls">
              <multiselect 
                id="production_status" 
                v-model="statusProductionChoose" 
                label="name"
                name="production_status" 
                track-by="name" 
                placeholder="Semua" 
                open-direction="bottom" 
                :options="productionStatus" 
                :searchable="true" 
                :loading="isLoading"
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-text-right">
            <button
              class="uk-button uk-button-default uk-margin-left"
              @click="clearFilter()"
            >
              <span
                uk-icon="icon: close; ratio: 0.9"
                class="uk-margin-small-right"
              />Clear
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-left"
              @click="handleFind()"
            >
              <span
                uk-icon="icon: search; ratio: 0.9"
                class="uk-margin-small-right"
              />Cari
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin">
      <button
        v-if="isCanAccessUser(`add`, `Kandang`)"
        class="uk-button uk-button-default uk-width-expand uk-width-auto@s uk-margin-right"
        @click="$router.push('kandang-ayam/import')"
      >
        <span
          uk-icon="icon: upload; ratio: 0.7"
          class="uk-margin-small-right"
        />Import
      </button>
      <router-link
        v-if="isCanAccessUser(`add`, `Kandang Ayam`)"
        class="uk-button uk-button-default"
        to="/admin/kandang-ayam/add"
      >
        <span
          uk-icon="icon: plus; ratio: 0.7"
          class="uk-margin-small-right"
        />Tambah Kandang
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { isCanAccess } from '@/utils/auth'

export default {
  data() {
    return {
      isLoading : false,
      metaFilter: {
        name: '',
        mitra: '',
        field_supervisor: '',
        cage_staff: '',
        cage_category_id: '',
        current_cycle: '',
        log_activity: '',
        production_status: null
      },
      cageCategoryChoose: '',
      statusProductionChoose: '',
      cageCategoryFilters: [],
      productionStatus: [
        {
          name: 'Aktif',
          id: 1
        },
        {
          name: 'Rehat',
          id: 2
        },
        {
          name: 'Tidak Aktif',
          id: 0
        }
      ],
      activityChoose: '',
      activityFilters: []
    }
  },
  computed : {
    ...mapGetters({
      cageCategorys: 'cageCategory/cageCategorys',
      activities: 'activity/activities'
    })
  },
  watch: {
    async cageCategoryChoose(val){
      await this.getActivity({ cage_category_id: val.id, is_all: true})
      this.activityFilters = this.activities ? this.activities : []
    }
  },
  async mounted() {
    await this.getCageCategory({is_all: true, status:true})
    this.cageCategoryFilters = this.cageCategorys ? this.cageCategorys : []
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getCageCategory: 'cageCategory/getCageCategory',
      getCage: 'cage/getCage',
      getActivity: 'activity/getActivity'
    }),
    ...mapMutations({
      setMetaCage: 'cage/SET_META'
    }),
    handleFind () {
      this.metaFilter.page = 1
      this.metaFilter.cage_category_id = this.cageCategoryChoose ? this.cageCategoryChoose.id : ''
      this.metaFilter.production_status = this.statusProductionChoose ? this.statusProductionChoose.id : null
      this.metaFilter.log_activity = this.activityChoose ? this.activityChoose.name : ''
      this.setMetaCage({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async clearFilter() {
      const app = this
      app.cageCategoryChoose = ''
      app.activityChoose = ''
      app.statusProductionChoose = ''
      app.metaFilter.name = ''
      app.metaFilter.mitra = ''
      app.metaFilter.cage_category_id = ''
      app.metaFilter.current_cycle = ''
      app.metaFilter.cage_staff = ''
      app.metaFilter.log_activity = ''
      app.metaFilter.field_supervisor = ''
      
      await app.handleFind()
    }
  }
}
</script>
