<template>
  <div
    id="modal-delete-failed"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-delete-cage"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Gagal Hapus Kandang
      </div>
      <p style="font-size:15px">
        Terdapat Kegiatan Kandang Aktif dalam Kandang
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-primary uk-margin-left uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Tutup
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  }
}
</script>
