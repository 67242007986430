<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider uk-table-middle">
        <TableHeader />
        <template v-if="!is_loading">
          <tbody v-if="cages.meta.total_count > 0">
            <tr
              v-for="(cage, i) in cages.data"
              :key="i"
            >
              <td>{{ cage.cage_code || "-" }}</td>
              <td>{{ cage.name || "-" }}</td>
              <td>{{ cage.farm || "-" }}</td>
              <td class="uk-flex uk-flex-center">
                <div :class="cage.status == false ? 'status-inactive' : 'status-active' || '-'">
                  {{ cage.status == false ? "Tidak Aktif" : "Aktif" || "-" }}
                </div>
              </td>
              <td>
                <div :class="cage.production_status == 0 ? 'status-inactive' : cage.production_status == 1 ? 'status-active' : cage.production_status == 2 ? 'status' : '-' || '-'">
                  {{ cage.production_status == 0 ? "Tidak Aktif" : cage.production_status == 1 ? "Aktif" : cage.production_status == 2 ? "Rehat" : "-" || "-" }}
                </div>
              </td>
              <td>{{ cage.cage_staff || "-" }}</td>
              <td>{{ cage.field_supervisor || "-" }}</td>
              <td>{{ cage.cage_category || "-" }}</td>
              <td>{{ cage.log_cycle_count || 0 }}</td>
              <td>{{ cage.current_log_activity || "-" }}</td>
              <td>{{ formatDate(cage.created_at) || "-" }}</td>
              <td>{{ formatDate(cage.updated_at) || "-" }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`delete`, `Kandang Ayam`)"
                  v-lazy="`${images}/icon/trash.svg`"
                  alt=""
                  class="uk-margin-small-right img-click"
                  @click="showDeleteConfirmModal(cage)"
                >
                <img
                  v-if="isCanAccessUser(`edit`, `Kandang Ayam`)"
                  v-lazy="`${images}/icon/edit-black.svg`"
                  alt=""
                  class="img-click"
                  @click="showEdit(cage.id)"
                >
                <img
                  v-if="isCanAccessUser(`view`, `Kandang Ayam`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-left img-click"
                  @click="showDetail(cage.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="9"
          />
        </template>
        <template v-else>
          <loading-table :colspan="9" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="cages.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Pagination from "@/components/globals/Pagination"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import { STATUS } from "@/utils/constant"
import { dateUtcParanjeString } from "@/utils/formater"
import { PREFIX_IMAGE } from "@/utils/constant"
import { isCanAccess } from "@/utils/auth"
import TableHeader from "./tableHeader"

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    TableHeader
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed: {
    ...mapGetters({
      cages: "cage/cages",
      meta: "cage/meta"
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getCage(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
    this.setMeta({
      ...this.meta,
      ["order_by"]: "updated_at",
      ["sort_by"]: "DESC"
    })
    await this.getCage(this.meta)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getCage: "cage/getCage"
    }),
    ...mapMutations({
      setMeta: "cage/SET_META",
      setModalDelete: "cage/SET_MODAL_DELETE"
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDeleteConfirmModal(cage) {
      window.UIkit.modal("#modal-delete-confirm").show()
      this.setModalDelete(cage)
    },
    showDetail(id) {
      this.$router.push(`/admin/kandang-ayam/detail/${id}`)
    },
    showEdit(id) {
      this.$router.push(`/admin/kandang-ayam/edit/${id}`)
    },
    changeSortDescending(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    },
    changeSortAscending(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    }
  }
}
</script>

<style scoped>
.status-active {
  border-radius: 8px;
  background: rgba(20, 182, 20, 0.16);
  border: 1px solid rgba(20, 182, 20, 0.2);
  color: #14b614;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
.status-inactive {
  border-radius: 8px;
  background: rgba(237, 94, 94, 0.16);
  border: 1px solid rgba(237, 94, 94, 0.2);
  color: #ed5e5e;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
.status {
  border-radius: 8px;
  background: rgba(237, 165, 92, 0.16);
  border: 1px solid rgba(237, 165, 92, 0.16);
  color: #eda55c;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
</style>
